import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { PageProp } from "."
import { Section } from "../components/atoms/Section"
import { Languages } from "../provider"
import { useLang } from "../components/Header/LanguageToggle/useLang"

const Title: React.FC<{ title: string }> = ({ title }) => {
  return (
    <h4 className="mt-4 text-lg font-bold leading-tight tracking-wider text-vp-blue">
      {title}
    </h4>
  )
}

const Text = {
  [Languages.EN]: (
    <div className="w-full h-full max-w-4xl pb-40 mx-auto leading-relaxed text-gray-800">
      <Title title="PRIVACY POLICY" />
      <br />
      <div className="serif">
        <div className="w-full text-right mb-6">
          <p>October 1st 2024</p>
          <p>VantagePoint K.K.</p>
          <p>Managing Partner Duncan Harrison</p>
        </div>
        <p>
          VantagePoint is an enterprise providing professional placement
          services authorized by the Minister of Health, Labor and Welfare, and
          in that capacity it is fully committed to its obligation to society
          with regard to the protection of all personal data it handles, and
          places importance on the protection of the rights of the data subjects
          and the laws and regulations pertaining to personal data. VantagePoint
          declares hereby that it will devise a compliance program to give
          concrete expression to the policy set out below, and maintain a
          constant awareness of developments such as the latest trends in
          information technology, changes in social requirements, and changes in
          the management environment, and devote company-wide efforts to the
          continual improvement of its management system for the protection of
          personal data.
        </p>
        <ol
          className="ml-10"
          style={{ listStyle: "disc", listStylePosition: "outside" }}
        >
          <li>
            VantagePoint shall limit its collection, use, and provision of
            personal data only to the extent necessary for its legitimate
            business operations, hiring of employees and personnel
            administration, and personal data will not be collected, used and
            provided beyond the necessary scope. VantagePoint will also take
            necessary measures to this purpose.
          </li>
          <li>
            VantagePoint shall observe all laws, regulations, and other rules
            relating to the protection of personal data.
          </li>
          <li>
            VantagePoint shall, in order to prevent risks such as leakage, loss,
            or damage of personal data, continuously improve proper security
            measures, and allocate management resources appropriate for its
            business circumstances. Additionally, in the event of an emergency,
            VantagePoint shall promptly take corrective measures.
          </li>
          <li>
            VantagePoint shall respond promptly, sincerely, and appropriately to
            any complaints or inquiries regarding the handling of personal data.
          </li>
        </ol>
        <br />

        <Title title="All enquiries relating to protection of personal data" />
        <br />
        <p>VantagePoint K.K. Privacy Officer</p>
        <p>
          Roppongi Hills North Tower 3F 6-2-31 Roppongi, Minato-ku, Tokyo,
          106-0032 Japan
        </p>
        <p>
          E-mail:{" "}
          <a
            className="text-blue-500 underline"
            href="mailto: info@vpointcareers.com"
          >
            info@vpointcareers.com
          </a>{" "}
        </p>
        <p>
          TEL:{" "}
          <a className="text-blue-500 underline" href="tel: 0354134433">
            03-5413-4433
          </a>
          (Contact hours 9:30～17:30 Weekdays except public holidays)　
        </p>
        <p>
          * Enquiries raised during the end of year holiday period will be
          serviced on the next working day following the holiday period.
        </p>
        <p>
          *Phone calls may be recorded for the purposes of confirmation,
          record-taking, and similar.
        </p>
        <br />
        <Title title="Generally understood items regarding retained personal data" />
        <ol
          className="ml-6"
          style={{ listStyle: "decimal", listStylePosition: "outside" }}
        >
          <li>
            Business Name, Address, and Representative Name
            <br />
            VantagePoint K.K.
            <br />
            Roppongi Hills North Tower 3F 6-2-31 Roppongi, Minato-ku, Tokyo,
            106-0032 Japan
            <br />
            Managing Partner Duncan Harrison
          </li>
          <br />
          <li>
            Contact details of the Privacy Officer
            <br />
            E-mail:{" "}
            <a
              className="text-blue-500 underline"
              href="mailto: info@vpointcareers.com"
            >
              info@vpointcareers.com
            </a>
            <br />
            TEL:{" "}
            <a className="text-blue-500 underline" href="tel: 0354134433">
              03-5413-4433
            </a>
            (Contact hours 9:30～17:30 Weekdays except public holidays)
            <br />* Enquiries raised during the end of year holiday period will
            be serviced on the next working day following the holiday period.
            <br />
            *Phone calls may be recorded for the purposes of confirmation,
            record-taking, and similar.
          </li>
          <br />
          <li>
            Details of all retained personal data and its purpose of use
            <br />
            Details of the personal information which will be retained and its
            purpose of use are as follows:
            <br />
            <br />
            <p>
               [Personal information of job applicants related to the paid
              recruitment service]
            </p>
            <ol
              className="ml-6"
              style={{ listStyle: "disc", listStylePosition: "outside" }}
            >
              <li>
                In order to undertake paid recruitment services (including the
                procedures required under relevant laws and ordinances, such as
                the Employment Security Act, management of members and
                employees, contacting related parties, information related to
                the service, introduction of employment opportunities,
                introductions to employers, management of filled positions,
                etc.).
              </li>
            </ol>
            <p>
              [Personal information of users of our outsourcing referral
              service]
            </p>
            <ol
              className="ml-6"
              style={{ listStyle: "disc", listStylePosition: "outside" }}
            >
              <li>
                For various procedures related to use of the service, provision
                of the service, and guidance of information related to the
                service{" "}
              </li>
            </ol>
            <p>
              [Personal information of applicants applying for positions at this
              company]
            </p>
            <ol
              className="ml-6"
              style={{ listStyle: "disc", listStylePosition: "outside" }}
            >
              <li>
                For employment related work (contacting associated parties,
                selection of candidates and jobs, etc.)
              </li>
            </ol>
            <p>[Personal information of employees of this company]</p>
            <ol
              className="ml-6"
              style={{ listStyle: "disc", listStylePosition: "outside" }}
            >
              <li>
                For human resource management (social insurance procedures,
                employee welfare, performance evaluation, etc.) and company PR
                operations (content for articles related to this company, etc.).
              </li>
            </ol>
            <p>
              [Personal information of executives and employees of business
              partners]
            </p>
            <ol
              className="ml-6"
              style={{ listStyle: "disc", listStylePosition: "outside" }}
            >
              <li>
                In order to conduct business negotiations and fulfill contracts,
                manage business partner information, process payments and
                deposits, and perform other necessary works related to our
                business purposes.
              </li>
            </ol>
            <p>
              [Personal information of individuals who have contacted us, raised
              a complaint, or similar actions]
            </p>
            <ol
              className="ml-6"
              style={{ listStyle: "disc", listStylePosition: "outside" }}
            >
              <li>
                For dealing with relevant matters, complaints, etc., as well as
                conflict resolution.
              </li>
            </ol>
          </li>
          <br />
          <li>
            Contact for complaints regarding the handling of retained personal
            data, requests for disclosure, etc.
            <br />
            Enquiries regarding the handling of personal information for
            disclosure are handled by:
            <br />
            <br />
            VantagePoint K.K.
            <br />
            Roppongi Hills North Tower 3F 6-2-31 Roppongi, Minato-ku, Tokyo,
            106-0032 Japan <br />
            E-mail:{" "}
            <a
              className="text-blue-500 underline"
              href="mailto: info@vpointcareers.com"
            >
              info@vpointcareers.com
            </a>
            <br />
            TEL:{" "}
            <a className="text-blue-500 underline" href="tel: 0354134433">
              03-5413-4433
            </a>
            (Contact hours 9:30～17:30 Weekdays except public holidays)
            <br />
            * Enquiries raised during the end of year holiday period will be
            serviced on the next working day following the holiday period.
            <br />
            *Phone calls may be recorded for the purposes of confirmation,
            record-taking, and similar.
            <br />
          </li>
          <br />
          <li>
            Procedures regarding requests for disclosure, etc.
            <br />
            We will handle requests for disclosure (such as those listed below)
            of personal information from the individual in question and proxies.
            <ol
              className="ml-6"
              style={{ listStyle: "disc", listStylePosition: "outside" }}
            >
              <li>Notification of purpose of use</li>
              <li>Disclosure (including records provided to third parties)</li>
              <li>Revision, addition, or deletion of details</li>
              <li>
                Suspension of use, erasure, and suspension of supplying to third
                parties
              </li>
            </ol>
            Please contact our Privacy Officer (see 4, above) when making a
            request for disclosure or similar.
            <br />
            We will then inform you of the specific process.
          </li>
          <br />
          <li>
            Measures taken for the secure management of retained personal
            information
            <br />
            We will prevent unauthorized access to personal information
            entrusted to us, and the loss, destruction, falsification, and
            leakage of personal information by taking organizational, physical,
            personnel, and by restricting access to personal information files,
            recording access logs, and implementing security measures to prevent
            unauthorized access from outside the company.
          </li>
        </ol>
        <br />
        <Title title="Purposes of use of personal information acquired via methods other than directly in writing" />
        <p>
          The purpose of use of personal information will, as a general rule, be
          made clear to the individual, and the individual will indicate their
          acceptance of that use, before they provide the information. In all
          other cases the individual will be notified, or a public notification
          will be made.
        </p>
        <ol
          className="ml-6 flex flex-col gap-4 parenthesis"
          style={{
            listStyle: "decimal",
            listStylePosition: "outside",
          }}
        >
          <li>
            <p>
              Personal information received in the course of paid recruitment
              services
            </p>
            <p>
              For the purposes of undertaking procedures required under the
              relevant laws and ordinances such as the Employment Security Act,
              management of members and employees, contacting related parties,
              introduction of employment opportunities, introductions to
              employers, management of filled positions, use in statistics and
              analysis for publication, and other activities within the scope of
              such paid recruitment services.
            </p>
          </li>

          <li>
            <p>
              Personal information provided by users of our outsourcing referral
              service
              <p>
                The information will be used within the scope of purposes such
                as managing user information, contacting users, introducing
                outsourced projects, providing information to the ordering
                company, managing projects, and statistics and analysis for
                publication in various media, etc.
              </p>
            </p>
          </li>
          <li>
            <p>
              Personal information received during the selection of employees
              <p>
                For the purposes of contacting related parties, sending of
                documents, selection, employment procedures, human resources
                management after entering the company, and other activities
                within the scope of such services.
              </p>
            </p>
          </li>
          <li>
            <p>
              Telephone recording of contacting related parties, etc.
              <p>
                Telephone calls may be recorded for the purposes of confirming
                the content of calls and making records, etc.
              </p>
            </p>
          </li>
        </ol>
        <br />
        <Title title="About Cookies" />
        <p>
          VantagePoint uses Cookies and JavaScript to protect your privacy and
          make our site more convenient, and collects the information you
          provide to us, with the exclusion of those that can be used to
          identify specific individuals. VantagePoint also uses third party
          advertising tools and analytics tools which acquire user activity
          history within our website. Users of VantagePoint’s website can refuse
          some or all of browser cookies, and set browser to display a warning
          whenever our website sets or accesses a cookie. Please note that
          disabling or refusing cookies may result in the inability to access
          some portions of VantagePoint’s website, or prevent some features from
          functioning properly.
        </p>
      </div>
    </div>
  ),
  [Languages.JP]: (
    <div
      className="w-full h-full max-w-4xl pb-40 mx-auto leading-relaxed text-gray-800"
      style={{ fontFamily: "Noto Serif JP" }}
    >
      <Title title={"個人情報保護管理方針"} />
      <br />
      <div className="jp-serif">
        <div className="w-full text-right mb-6">
          <p>2024年10月1日</p>
          <p>株式会社バンテージポイント</p>
          <p>代表取締役 ハリソン・ダンカン・ジョン</p>
        </div>
        <p>
          当社は、厚生労働大臣の許可を受けた有料職業紹介事業者として、取扱う全ての個人情報の保護について社会的使命を十分に認識し、本人の権利の保護、個人情報に関する法規制等を遵守します。
          また、以下に示す方針を具現化するため、最新のＩＴ技術の動向、社会的要請の変化、経営環境の変動等を常に認識しながら、その継続的改善に、全社を挙げて取り組むことをここに宣言します。
        </p>
        <ol
          className="ml-10"
          style={{ listStyle: "disc", listStylePosition: "outside" }}
        >
          <li>
            個人情報は、当社の正当な事業遂行上並びに従業員の雇用、人事管理上必要な範囲に限定して、収集、利用及び提供し、特定された利用目的の達成に必要な範囲を超えて取扱いません。また、そのために必要な措置を講じます。
          </li>
          <li>
            個人情報保護に関する法令、国が定める指針及びその他の規範を遵守します。
          </li>
          <li>
            個人情報の漏えい、滅失、き損などのリスクに対しては、合理的な安全対策を講じて防止すべく事業の実情に合致した経営資源を注入し個人情報セキュリティ体制を継続的に向上させます。また、万一の際には速やかに是正措置を講じます。
          </li>
          <li>
            個人情報取扱いに関する苦情及び相談に対しては、迅速、誠実かつ適切に対応します。
          </li>
        </ol>
        <br />

        <p>問い合わせ窓口</p>
        <p>〒106-0032東京都港区六本木6-2-31 　六本木ヒルズノースタワー3F</p>
        <p>株式会社バンテージポイント 個人情報取扱責任者</p>

        <p>
          E-mail:{" "}
          <a
            className="text-blue-500 underline"
            href="mailto: info@vpointcareers.com"
          >
            info@vpointcareers.com
          </a>{" "}
        </p>
        <p>
          TEL:{" "}
          <a className="text-blue-500 underline" href="tel: 0354134433">
            03-5413-4433
          </a>
          (受付時間　平日9:30～17:30)　
        </p>
        <p>※年末年始は翌営業日の対応となります。</p>
        <p>
          ※電話応対の際には、確認及び記録等を目的として通話内容を録音する場合があります。
        </p>
        <br />
        <Title title={"保有個人データに関する周知事項"} />
        <ol
          className="ml-6 flex flex-col gap-4"
          style={{ listStyle: "decimal", listStylePosition: "outside" }}
        >
          <li>
            事業者の名称、住所、代表者の氏名
            <br />
            株式会社 バンテージポイント
            <br />
            〒106-0032東京都港区六本木6-2-31　六本木ヒルズノースタワー3F
            <br />
            代表取締役　ハリソン・ダンカン・ジョン
          </li>
          <li>
            個人情報保護管理者の連絡先
            <br />
            E-mail:{" "}
            <a
              className="text-blue-500 underline"
              href="mailto: info@vpointcareers.com"
            >
              info@vpointcareers.com
            </a>
            <br />
            TEL:{" "}
            <a className="text-blue-500 underline" href="tel: 0354134433">
              03-5413-4433
            </a>
            (受付時間　平日9:30～17:30)　
            <br />
            ※年末年始は翌営業日の対応となります。
            <br />
            ※電話応対の際には、確認及び記録等を目的として通話内容を録音する場合があります。
          </li>
          <li>
            すべての保有個人データとその利用目的
            <br />
            当社の保有個人データとその利用目的は以下の通りです。
            <ol
              className="ml-6"
              style={{ listStyle: "disc", listStylePosition: "outside" }}
            >
              <li>
                有料職業紹介事業における求職者の方の個人情報有料職業紹介事業
                (職業安定法等関連法令に定めのある手続への対応、登録者情報の管理、連絡、サービスに関連する情報のご案内、求人案件のご紹介、求人者へのご提供、成約案件の管理等)
                遂行のため
              </li>
              <li>
                業務委託案件の紹介サービスにおけるご利用者の方の個人情報ご利用に関する各種手続き、サービスのご提供、サービスに関連する情報のご案内のため
              </li>
              <li>
                当社従業員採用応募者の方の個人情報
                <br />
                当社従業員採用業務(連絡、選考等)のため
              </li>
              <li>
                当社従業員の個人情報
                <br />
                人事労務管理(社会保険手続、福利厚生、人事考課等)及び当社PR業務(当社関連記事の媒体掲載等)のため
              </li>
              <li>
                お取引先の役職員の方の個人情報
                <br />
                商談および契約の履行、取引先情報管理、支払・入金の処理、その他当社の事業目的に関連する必要な業務の遂行のため
              </li>
              <li>
                お問い合わせ、苦情等をいただいた方の個人情報
                <br />
                お問い合わせ、苦情等への対応並びに紛争解決のため
              </li>
            </ol>
          </li>
          <li>
            保有個人データの取り扱いに関する苦情、開示等の求めの申し出先
            <br />
            保有個人データの取り扱いに関するお問い合わせは下記窓口で受け付けております。
            <br />
            <br />
            株式会社 バンテージポイント
            <br />
            〒106-0032東京都港区六本木6-2-31　六本木ヒルズノースタワー3F <br />
            E-mail:{" "}
            <a
              className="text-blue-500 underline"
              href="mailto: info@vpointcareers.com"
            >
              info@vpointcareers.com
            </a>
            <p>
              TEL:{" "}
              <a className="text-blue-500 underline" href="tel: 0354134433">
                03-5413-4433
              </a>
              (受付時間　平日9:30～17:30)　
            </p>
            <p>※年末年始は翌営業日の対応となります。</p>
            ※電話応対の際には、確認及び記録等を目的として通話内容を録音する場合があります。
            <br />
          </li>
          <li>
            開示等の求めに応じる手続き
            <br />
            当社は、本人または代理人からの保有個人データに対する以下の開示等の求めに対応いたします。
            <ol
              className="ml-6"
              style={{ listStyle: "disc", listStylePosition: "outside" }}
            >
              <li>利用目的の通知</li>
              <li>開示(第三者提供記録の開示を含む)</li>
              <li>内容の訂正、追加または削除</li>
              <li>利用停止、消去、第三者への提供停止</li>
            </ol>
            開示等の求めを行う場合は、４．に記載した個人情報保護管理者へご連絡ください。所定の手続きについてご案内いたします。
          </li>
          <li>
            保有個人データの安全管理のために講じた措置
            当社がお預かりした個人情報は、個人情報ファイルへのアクセス制限の実施、アクセスログの記録及び外部からの不正アクセス防止のためのセキュリティ対策の実施等、組織的、物理的、人的、技術的施策を講じることで個人情報への不正な侵入、個人情報の紛失、破壊、改ざん、及び漏えい等を防止いたします。
          </li>
        </ol>
        <br />
        <Title title={"直接書面以外の方法で取得する個人情報の利用目的"} />
        <p>
          個人情報の利用目的は、原則として当該の個人情報を提供いただく前に明示します。。その他、必要に応じて本人に通知または公表するものとします。
        </p>
        <ol
          className="ml-6 flex flex-col gap-4 parenthesis"
          style={{
            listStyle: "decimal",
            listStylePosition: "outside",
          }}
        >
          <li>
            <p>有料職業紹介事業において提供を受けた個人情報</p>
            <p>
              職業安定法等関連法令に定めのある手続への対応、登録者・求人者情報の管理、連絡、求人案件の紹介、求人者への提供、成約案件の管理、各種媒体等に掲載するための統計化と分析等を目的として、その範囲内で利用します
            </p>
          </li>
          <li>
            <p>業務委託案件の紹介サービスにおいて提供を受けた個人情報</p>
            <p>
              ご利用者情報の管理、ご利用者への連絡、業務委託案件の紹介、発注元企業への提供、案件の管理、各種媒体等に掲載するための統計化と分析等を目的として、その範囲内で利用します。
            </p>
          </li>
          <li>
            <p>従業員の採用選考において提供を受けた個人情報</p>
            <p>
              連絡、資料送付、選考、入社手続、入社後の人事労務管理等を目的として、その範囲内で利用します。
            </p>
          </li>
          <li>
            <p>お問い合わせ等の電話録音</p>
            <p>
              お問い合わせ等の内容確認及び記録等を目的として、電話の通話内容を録音する場合があります。
            </p>
          </li>
        </ol>

        <br />
        <Title title={"Cookie等の利用について"} />
        <p>
          当社は、プライバシーの保護、利便性の向上等を目的としてCookieやJavaScript等の技術を利用し、ご提供いただいた情報のうち個人が特定できない属性情報を取得することがあります。また、広告の配信のために広告サービスを、当社ウェブサイト内における利用者の行動履歴の取得のためにアクセス解析ツールを使用しております。当社のウェブサイトをご利用になる方は、全部または一部のブラウザ・Cookieを拒否することもできますし、またはウェブサイトがCookieを設定し若しくはCookieにアクセスする場合には、その旨を警告するようにブラウザを設定することもできます。なお、Cookieを無効化、または拒否した場合には、ウェブサイトの一部がアクセス不能になるか、または適切に機能しなくなる可能性がありますので、ご注意ください。
        </p>
      </div>
    </div>
  ),
}

const PrivacyPolicyPage: React.FC<PageProp> = ({ location }) => {
  const lang = useLang()
  return (
    <Layout location={location}>
      <SEO title="Privacy Policy" />
      <Section>{Text[lang]}</Section>
    </Layout>
  )
}

export default PrivacyPolicyPage
